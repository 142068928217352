<template>
 <div>
  <b-card bg-variant="light">
    <!-- <b-form-group 
      label-cols-lg="3"
      label="Product Link"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      class="mb-0"
    >
      <b-form-group
        label="Edition:"
        label-for="nested-edition"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-edition"
          v-model="attr_copy.edition"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Price:"
        label-for="nested-price-cents"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-price-cents"
          v-model="attr_copy.price_cents"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Description:"
        label-for="nested-description"
        label-cols-sm="3"
        label-align-sm="right"
      >
        <b-form-input id="nested-description"
          v-model="attr_copy.description"
        ></b-form-input>
      </b-form-group>
    </b-form-group> -->
    <link-prevue :url="attr_copy['url-link']">
      <!-- <template slot-scope="props">
        <div>
        <b-img thumbnail fluid
        src="https://static.wikia.nocookie.net/marveldatabase/images/0/01/Iron_Man_Vol_1_100.jpg/revision/latest?cb=20171224171312" :alt="props.title"
        />
        SITE NAME: {{ props.siteName}} TITLE: {{props.title}} DESCRIPTION: {{props.description}} IMAGE: {{props.img}} URL: {{props.url}}
        </div>
      </template> -->
    </link-prevue>
    <b-button block variant="primary" @click="callApi">Update Product Link</b-button>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'
import {amsMofifiedAttrs} from '@/utils'
import LinkPrevue from 'link-prevue'

export default {
  components: {
    LinkPrevue
  },
  props: {
    id: {
      Type: String
    }
  },
  data () {
    return {
      attr_copy: {},
      attr_orig: {}
    }
  },
  methods: {
    callApi () {
      let attr_params = amsMofifiedAttrs(this.attr_copy, this.attr_orig)
      http_v1.patch (
        'api/admin/v1/product_item_links/' + this.id, {
          ...attr_params
        }
      ).then(() => {
        // this.$router.push( {name: 'ProductItemLinkIndex'})
        this.$router.go(-1)
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    },
  },
  mounted () {
    http_v1.get('api/admin/v1/product_item_links/' + this.id, {
    }).then(response => {
      if( response.data) {
        var message = response.data.message || JSON.stringify(response.data)
        this.$bvToast.toast(message, {
          title: 'Success',
          autoHideDelay: 5000,
          appendToast: true
        })
        this.attr_copy = Object.assign( {}, response.data.data.attributes)
        this.attr_orig = Object.assign( {}, response.data.data.attributes)
      }
    }).catch(error => {
      if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
  }
}
</script>